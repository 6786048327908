import './DestinationDetail.css';
import React from 'react';
import { useParams } from 'react-router';
import {
  useGetDestinationById,
} from 'src/api/destinationApi';
import { useEffect, useState } from 'react';
import { DestinationType } from 'src/api/models';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DestinationDetail: React.FC = () => {
  const params = useParams<{ id: string }>();
  const destinationId = Number(params.id);
  const { t, i18n } = useTranslation();
  //
  const [destinationDisplay, setDestinationDisplay] = useState<DestinationType>();
  //Function
  const getDestinationById = useGetDestinationById();

  //Get Destination
  useEffect(() => {
    getDestinationById(destinationId)
      .then((data) => {
        setDestinationDisplay(data);
      });
  }, [getDestinationById, destinationId]);


  return (
    <PageContainer>
      <PageHeader>
        <NavLink to={'/'}>{t("Home")}</NavLink>{` / `}<NavLink to={'/destination'}>{t("Điểm đến Golf")}</NavLink>{` / `}{t(destinationDisplay?.destinationTitle)}
      </PageHeader>
      <div className="destination-value">
        <div dangerouslySetInnerHTML={{ __html: eval(`destinationDisplay?.${i18n.language}??''`) }} />
      </div>
    </PageContainer>
  );
};

export default DestinationDetail;
