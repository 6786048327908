import { MenuType } from 'src/api/models';
import './VerticalMenuItem.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IVerticalMenuItem {
  menu: MenuType;
  hidden: () => void;
}

const VerticalMenuItem: React.FC<IVerticalMenuItem> = (props) => {
  const { menu, hidden } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>();

  //Main
  return (
    <div className={`vertical-menu-item`}>
      <div className='vertical-menu-title'>
        <div className='vertical-menu-href' onClick={() => {
          if (menu.href) {
            navigate(menu.href);
            hidden();
          } else if (menu.childs?.length > 0) {
            setActive(!active);
          }
        }}><span className="material-icons">{menu.icon ? menu.icon : "apps"}</span><span>{t(menu.title)}</span></div>
        {menu.childs?.length > 0 ? <span className="material-icons" onClick={() => setActive(!active)}>expand_circle_down</span> : null}
      </div>

      {active && menu.childs?.length > 0 ? <div className="dropdown-content">
        {menu.childs.map(child => <a key={`child-menu${child.menuId}`} onClick={() => {
          if (child.href) {
            navigate(child.href);
            hidden();
          }
        }}>{t(child.title)}</a>)}
      </div> : null}
    </div>
  );
};

export default VerticalMenuItem;
