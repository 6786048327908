import { useTranslation } from 'react-i18next';
import './FeaturedTab.css';
import React, { useState, useEffect } from 'react';
import FeaturedNews from './FeaturedNews/FeaturedNews';
import FeaturedDestination from './FeaturedDestination/FeaturedDestination';
import FeaturedExperience from './FeaturedExperience/FeaturedExperience';


const FeaturedTab: React.FC = () => {


  const { t } = useTranslation();

  const FEATURE_NEW = "FEATURE_NEW";
  const FEATURE_EXPERIENCE = "FEATURE_EXPERIENCE";
  const FEATURE_DESTINATION = "FEATURE_DESTINATION";

  const [featureTab, setFeatureTab] = useState<string>(FEATURE_NEW);
  //Main
  return (
    <div className="featured-tab-container">
      <div className='inline feature-tab'>
        <div className={`feature-tab-item ${featureTab == FEATURE_NEW ? 'active' : ''}`} onClick={() => setFeatureTab(FEATURE_NEW)}>{t("Tin tức & Sự kiện")}</div>
        <div className={`feature-tab-item ${featureTab == FEATURE_EXPERIENCE ? 'active' : ''}`} onClick={() => setFeatureTab(FEATURE_EXPERIENCE)}>{t("Kinh nghiệm du lịch Golf")}</div>
        <div className={`feature-tab-item ${featureTab == FEATURE_DESTINATION ? 'active' : ''}`} onClick={() => setFeatureTab(FEATURE_DESTINATION)}>{t("Điểm đến Golf")}</div>
      </div>
      {featureTab == FEATURE_NEW && <FeaturedNews />}
      {featureTab == FEATURE_EXPERIENCE && <FeaturedExperience />}
      {featureTab == FEATURE_DESTINATION && <FeaturedDestination />}
    </div>
  );
};

export default FeaturedTab;
