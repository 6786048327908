import './ProductPage.css';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useState } from 'react';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Products from 'src/components/Products/Products';
import { CategoryType, RealmType, StaticPageType } from 'src/api/models';
import { useGetProductCategoryById } from 'src/api/productCategoryApi';
import { useGetProductRealmById, useGetStaticPageByKey } from 'src/api/backend-api';
import { useAddPopup } from 'src/state/application/hooks';
import styled from 'styled-components';

const ProductPage: React.FC = () => {
  //Value
  const params = useParams<{ type: string; id: string }>();
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [productCategoryId, setProductCategoryId] = useState<number>();
  const [productRealmId, setProductRealmId] = useState<number>();
  const { t, i18n } = useTranslation();
  const getCategory = useGetProductCategoryById();
  const getRealm = useGetProductRealmById();
  const addPopup = useAddPopup();
  const CATEGORY = "category";
  //State
  const [staticPage, setStaticPage] = useState<StaticPageType>();
  //Function
  const getStaticPageByKey = useGetStaticPageByKey();

  useEffect(() => {
    if (code) {
      getStaticPageByKey(code).then((res) => {
        setStaticPage(res);
      })
        .catch((error) => {
          addPopup({
            error: {
              message: 'Đã có lỗi xảy ra',
              title: error.message,
            },
          });
        });
    }
  }, [addPopup, code, getStaticPageByKey])

  useEffect(() => {
    const id = Number(params.id);
    if (params.type == CATEGORY) {
      setProductCategoryId(id);
      getCategory(id).then((res) => {
        setTitle(res.productCategoryName);
        setCode(`PRODUCT_PAGE_CATEGORY_${res.productCategoryCode}`);
      }).catch((error) => {
        addPopup({
          error: {
            message: 'Đã có lỗi xảy ra',
            title: error.message,
          },
        });
      })
    } else {
      setProductRealmId(id);
      getRealm(id).then((res) => {
        setTitle(res.productRealmName);
        setCode(`PRODUCT_PAGE_REALM_${res.productRealmCode}`);
      }).catch((error) => {
        addPopup({
          error: {
            message: 'Đã có lỗi xảy ra',
            title: error.message,
          },
        });
      })
    }
  }, [addPopup, getCategory, getRealm, params, params.id, params.type])

  //Main
  return (
    <>
      {staticPage?.staticPageTitle ? <img className='product-page-banner' src={staticPage?.staticPageTitle} /> : null}
      <ProductPageContainer>
        <PageHeader>
          <NavLink to={'/'}>{t("Home")}</NavLink>{` / `}{t(title)}
        </PageHeader>
        {staticPage ? (
          <div
            dangerouslySetInnerHTML={{
              __html: eval(`staticPage?.${i18n.language}??''`)
            }}
          />
        ) : null}
      </ProductPageContainer>
      <div className='product-page-container'>
        {productCategoryId || productRealmId ? <Products categoryId={productCategoryId} realmId={productRealmId} /> : null}
      </div>

    </>
  );
};

export default ProductPage;

export const ProductPageContainer = styled.div`
  background-color: white;
  margin: 0 5vw;
  padding: 0 20px;
  margin-top: -80px;
  max-width: 90vw;
  display: inline-block;
`;
