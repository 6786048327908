import { useTranslation } from 'react-i18next';
import './FeaturedDestination.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetDestination } from 'src/api/destinationApi';
import { DestinationType } from 'src/api/models';


const FeaturedDestination: React.FC = () => {
  //Value
  const navigate = useNavigate();
  //State
  const [firstDestination, setfirstDestination] = useState<DestinationType>();
  const [featuredDestination, setFeaturedDestination] = useState<DestinationType[]>([]);

  const getDestination = useGetDestination();

  const { t } = useTranslation();

  //End of function
  useEffect(() => {
    getDestination(1, 7)
      .then((data) => {
        if (data.items.length > 0) {
          setfirstDestination(data.items[0]);
          setFeaturedDestination(data.items.slice(1));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getDestination]);

  //Main
  return (
    <div className="featured-destination-container">
      {firstDestination ? <div className='first-destination-container' onClick={() => navigate(`/destination/${firstDestination.destinationId}`)}>
        <img className="first-destination-avatar" src={firstDestination.avatar} />
        <div className="first-destination-content">
          <div className="first-destination-item-title">{t(firstDestination.destinationTitle)}</div>
          <div className="first-destination-item-des">{t(firstDestination.description)}</div>
        </div>
      </div> : null}

      <div className="featured-destination-child">
        {featuredDestination.map((item: DestinationType, index: number) => {
          return (
            <div
              key={`feature${index}`}
              className="feature-destination-item feature-destination-resize"
              onClick={() => navigate(`/destination/${item.destinationId}`)}
              title={t(item.destinationTitle)}
            >
              <img className="feature-destination-avatar" src={item.avatar} />
              <div className="featured-destination-item-title">{t(item.destinationTitle)}</div>
            </div>
          );
        })}

      </div>

    </div>
  );
};

export default FeaturedDestination;
