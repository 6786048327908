import './Partner.css';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ImageType } from 'src/api/models';
import useModal from 'src/hooks/useModal';
import FullSizeImage from '../FullSizeImage/FullSizeImage';
import { useTranslation } from 'react-i18next';
import { useGetPartners } from 'src/api/partnerApi';

const Partner: React.FC = () => {
  //State
  const [images, setImages] = useState<ImageType[]>([]);
  const fullsizeModal = useModal(FullSizeImage, undefined, false);
  const { t } = useTranslation();

  const getImages = useGetPartners();

  useEffect(() => {
    getImages()
      .then((res) => {
        setImages(res);
      });
  }, [getImages]);

  //Main
  return (
    <div className="partner-image-container">
      <div className="partner-image-title">
        {t("ĐỐI TÁC CỦA CHÚNG TÔI")}
      </div>
      <div className="partner-image-content">
        {images.map((companyImage: ImageType, index: number) => {
          return (
            <div className="partner-image-child" key={`companyimage${index}`}>
              <img src={companyImage.fileUrl} onClick={() => {
                fullsizeModal.handlePresent({
                  images: images,
                  index: index,
                  hidden: fullsizeModal.handleDismiss
                })
              }} />
            </div>
          );
        })}
      </div>

    </div>
  );
};

export default Partner;
