import './Products.css';
import React, { useCallback } from 'react';
import { CategoryAttributeType, PageData, ProductType } from 'src/api/models';
import { useGetProduct } from 'src/api/backend-api';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductPcComponent from './ProductPcComponent/ProductPcComponent';
import Loading from '../Loading';
import { useGetCategoryAttribute } from 'src/api/productCategoryApi';

interface IProducts {
  realmId?: number;
  categoryId?: number;
  isHorizontally?: boolean;
}

const Products: React.FC<IProducts> = (props) => {
  const SIZE = 20;

  const { realmId, categoryId, isHorizontally } = props;

  //State
  const [products, setProducts] = useState([]);
  const [configs, setConfigs] = useState<{ [productCategoryId: number]: CategoryAttributeType[] }>({});


  const [page, setPage] = useState(1);

  const [hasMore, setHasMore] = useState(true);

  //End of state
  const getProduct = useGetProduct();
  const getCategoryAttribute = useGetCategoryAttribute();

  const fetchData = useCallback((reset: boolean) => {
    const categories = categoryId ? [categoryId] : [];
    const realms = realmId ? [realmId] : [];
    const cpage = reset ? 1 : page;
    getProduct('', cpage, SIZE, realms, categories).then((r: PageData<ProductType>) => {
      const categoryIds = r.items.filter(p => !(p.productCategoryId in configs)).map(p => p.productCategoryId);
      if (categoryIds.length > 0) {
        getCategoryAttribute(categoryIds).then((attRes) => {
          const nConfigs = { ...configs, ...attRes };
          setConfigs(nConfigs);
        })
      }

      const nList = reset ? r.items : [...products, ...r.items];
      setProducts(nList);
      if (nList.length < r.count) {
        setHasMore(true);
        setPage(cpage + 1);
      } else {
        setHasMore(false);
      }
    });
  }, [categoryId, configs, getCategoryAttribute, getProduct, page, products, realmId]);

  useEffect(() => {
    fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, realmId]);

  //Main
  return (
    <InfiniteScroll
      dataLength={products.length}
      height={'fit-content'}
      next={() => fetchData(false)}
      refreshFunction={() => fetchData(true)}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      hasMore={hasMore}
      className={`products-content ${isHorizontally ? 'horizontaly' : 'vetically'}`}
      loader={<Loading />}
    >
      {products.map((item: ProductType, index: number) => {
        return (
          <ProductPcComponent
            key={`productpccomponent${index}`}
            product={item}
            configs={configs[item.productCategoryId]}
            isHorizontally={isHorizontally}
          />
        );
      })}
    </InfiniteScroll>
  );
};

export default Products;
