/* eslint-disable react-hooks/exhaustive-deps */
import './Header.css';
import { useState } from 'react';
import React from 'react';
import InputComponent from '../InputComponent/InputComponent';
import { useEffect } from 'react';
import { useGetProduct } from 'src/api/backend-api';
import { MenuType, ProductType } from 'src/api/models';
import {
  useAddPopup,
  useGetCart,
  useGetnotificationBadge,
  useRemoveProfileInfo,
} from 'src/state/application/hooks';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router';
import useProfile from 'src/hooks/useProfile';
import { NavLink } from 'react-router-dom';
import useLogo from 'src/hooks/useLogo';
import Cart from '../Cart/Cart';
import SlideBar from '../SlideBar';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import { useTranslation } from 'react-i18next';
import { useGetAllMenu } from 'src/api/menuApi';
import { number2money } from 'src/utils/stringUtils';
import useDebounce from 'src/hooks/useDebounce';
import VerticalMenu from './VerticalMenu/VerticalMenu';

const Header: React.FC = () => {

  const { t, i18n } = useTranslation();
  //Define
  const SIZE = 20;
  const PAGE = 1;
  const navigate = useNavigate();
  const profile = useProfile();
  const cart = useGetCart();
  const notificationBadge = useGetnotificationBadge();
  const logo = useLogo();
  const [keyword, setKeyword] = useState('');
  const keywordDebounce = useDebounce(keyword, 500);
  const [elmSearch, setElmSearch] = useState(null);
  const [isCartVisible, setCartVisible] = useState(false);
  const [isVerticalMenu, setVerticalMenu] = useState(false);
  const [isShowAccountPopup, setShowAccountPopup] = useState(false);


  const [language, setLanguage] = useState<string>();
  const [menus, setMenus] = useState<MenuType[]>([]);

  //Function
  const getProduct = useGetProduct();
  const addPopup = useAddPopup();
  const removeProfileInfo = useRemoveProfileInfo();
  const getAllMenu = useGetAllMenu();

  const onChange = (name: string, _value: string) => {
    if (name === 'keyword') {
      setKeyword(_value);
    }
  };
  useEffect(() => {
    setLanguage(i18n.language);
  }, [])

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
      console.log(language);
      localStorage.setItem('DDC_I18N_LANGUAGE', language);
    }
  }, [language])


  useEffect(() => {
    getAllMenu().then((res) => {
      const parents = res.filter(m => !m.parentId);
      parents.forEach((parent) => {
        const childs = res.filter(m => m.parentId == parent.menuId);
        parent.childs = childs;
      })
      setMenus(parents);
    }).catch((err) => {
      addPopup({
        error: {
          title: "Đã có lỗi xảy ra",
          message: err.message
        }
      })
    })
  }, [])

  const onLogOut = () => {
    window.scrollTo(0, 0);
    removeProfileInfo();
    setShowAccountPopup(false);
  };

  const onLogIn = () => {
    window.scrollTo(0, 0);
    navigate('/auth-screen');
    setShowAccountPopup(false);
  };
  //End of funftion

  const wrapperSearchRef = React.createRef<HTMLDivElement>();
  // const wrapperSearchSpRef = React.createRef<HTMLDivElement>();




  //Search
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!wrapperSearchRef?.current?.contains(event.target as Node)) {
        setElmSearch(undefined);
      }
      // if (!wrapperSearchSpRef?.current?.contains(event.target as Node)) {
      //   setElmSearch(undefined);
      // }
    };
    document.addEventListener('click', handleClick);
    const key = keywordDebounce as string;
    if (key) {
      getProduct(key, PAGE, SIZE, [], [])
        .then((data) => {
          const elmSearch = data.items.map((product: ProductType, index: number) => {
            return (
              <LazyLoad height={75} overflow={true}>
                <div
                  key={'elm-search' + index}
                  className="elm-search-child"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setShowAccountPopup(false);
                    const url = `/product-detail/${product.productId}`;
                    navigate(url);
                    setElmSearch(undefined);
                    setKeyword("");
                  }}
                >
                  <div className="elm-search-img-child">
                    <img src={product.thumbAvatar} />
                  </div>
                  <div className="elm-search-details">
                    <div className="elm-search-details-des">
                      {t(product.productName)}
                    </div>
                    <div className="elm-search-price">
                      {t("Giá")}: {product.price ? number2money(product.price) : t('Liên hệ')}
                    </div>
                  </div>
                </div>
              </LazyLoad>
            );
          });
          setElmSearch(elmSearch);
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: t('Đã có lỗi xảy ra!'),
            },
          });
        });
    }
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [addPopup, getProduct, keywordDebounce]);

  return (
    <div className="header-container" id="header">
      <div className="nav-pc-header-top">
        <div className="nav-pc-header-top_left">
          <div className="nav-pc-header-top-child hidden-small"><div>{t("Kết nối")}</div></div>
          <i className="fab fa-facebook-f nav-pc-header-top-child m-1 p-0" onClick={() => window.open("https://facebook.com")} />
          <i className="fab fa-tiktok nav-pc-header-top-child m-1 p-0" onClick={() => window.open("https://titok.com")} />
        </div>
        <div className="nav-pc-header-top_right">

          <span className={`flag-icon flag-icon-gb ${language == 'en' ? 'active' : 'deactive'}`} onClick={() => setLanguage("en")}>&#8203;</span>
          <span className={`flag-icon flag-icon-vn ${language == 'vi' ? 'active' : 'deactive'}`} onClick={() => setLanguage("vi")}>&#8203;</span>
          <span className={`flag-icon flag-icon-jp ${language == 'jp' ? 'active' : 'deactive'}`} onClick={() => setLanguage("jp")}>&#8203;</span>


          {/* <div className="nav-pc-header-top-child" onClick={() => {
            window.scrollTo(0, 0);;
            navigate(`/static-page/ABOUT`);
          }} title="About us">
            <i className="far fa-question-circle"></i>&nbsp;<div className='hidden-small'>{t("Về chúng tôi")}</div>
          </div>
          <div className="nav-pc-header-top-child" onClick={() => {
            window.scrollTo(0, 0);
            navigate(`/news`);
          }} title="News">
            <i className="far fa-newspaper"></i>&nbsp;<div className='hidden-small'>{t("Tin tức")}</div>
          </div> */}
          <div style={{ color: 'lightgray' }}>|</div>
          {!profile ? <div className="nav-pc-header-top-child" onClick={() => {
            window.scrollTo(0, 0);
            navigate(`/auth-screen`);
          }} title="Login" >{t("Đăng nhập")}</div>
            : <div className="nav-pc-header-top-child" onClick={onLogOut} title={t("Đăng xuất")}>{t("Đăng xuất")}</div>}
        </div>
      </div>
      <div className="header-top">
        <div className="logo" onClick={() => {
          window.scrollTo(0, 0);
          navigate('/');
        }}><img src={logo} /></div>
        <div className="search" ref={wrapperSearchRef}>
          <InputComponent
            isDisable={false}
            value={keyword}
            name={'keyword'}
            width={'100%'}
            height={'100%'}
            inputType={'input'}
            placeholder={t('What do you want to search?')}
            onChange={onChange}
          />
          <div className="search-container elm-search">{elmSearch}</div>
        </div>
        <div className='header-menu inline hidden-small'>
          <ul>
            {menus.map((menu) => menu.childs?.length > 0 ? <li className="dropdown" key={`menu${menu.menuId}`}>
              <a className="dropbtn" href={menu.href}>{t(menu.title)}</a>
              <div className="dropdown-content">
                {menu.childs.map(child => <a key={`child-menu${child.menuId}`} href={child.href}>{t(child.title)}</a>)}
              </div>
            </li> : <li className='' key={`menu${menu.menuId}`}><a href={menu.href}>{t(menu.title)}</a></li>)}
          </ul>

        </div>
        <div className='header-icon' title={t("Shopping bag")} onClick={() => setCartVisible(!isCartVisible)}>
          <span className="material-icons">shopping_cart</span>
          {cart && cart.length > 0 ? <div className="badge">{cart.length}</div> : null}
        </div>
        {profile ? <NavLink className='header-icon' to={'/order-requirement'} title={t("Order list")}>
          <span className="material-icons">list_alt</span>
        </NavLink> : null}
        {/* {profile ? <NavLink className='header-icon' to={'/notification'} title={t("Notifications")}>
          <span className="material-icons">notifications</span>
          {notificationBadge ? <div className="badge">{notificationBadge}</div> : null}
        </NavLink> : null} */}

        <div className="account-container">
          {profile ? <img onClick={() => setShowAccountPopup(!isShowAccountPopup)} src={profile.info.avataUrl} title="Account" />
            : <i className="fas fa-user-circle" onClick={() => setShowAccountPopup(!isShowAccountPopup)} title="Account" />}
          <div className={`account-popup ${isShowAccountPopup ? 'show' : ''}`}>
            <div>
              <div className="btn-close" onClick={() => setShowAccountPopup(!isShowAccountPopup)}><i className="fas fa-times" /></div>
              {profile ? (
                <div className="log" onClick={onLogOut}>
                  <span className="material-icons">logout</span>
                  <div style={{ marginLeft: 10 }}>{t("Đăng xuất")}</div>
                </div>
              ) : (
                <div className="log" onClick={onLogIn}>
                  <span className="material-icons">login</span>
                  <div style={{ marginLeft: 10 }}>{t("Đăng nhập")}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='header-icon hidden-big'>
          <span className="material-icons" onClick={() => setVerticalMenu(true)}>menu</span>
        </div>
      </div>
      <SlideBar visible={isVerticalMenu} changeVisible={setVerticalMenu}>
        <span className="material-icons vertical-menu-clear" onClick={() => setVerticalMenu(false)}>clear</span>
        <VerticalMenu menus={menus} hidden={() => setVerticalMenu(false)} />
      </SlideBar>
      <SlideBar visible={isCartVisible} changeVisible={setCartVisible}>
        <div className="cart-header">
          <div><span className="material-icons cart-header-middle">shopping_cart</span><label className="cart-header-middle">{cart.length} {t('items')}</label> </div>
          <span className="material-icons cart-header-middle" onClick={() => setCartVisible(false)}>clear</span>
        </div>
        <Cart />
        <ButtonComponent onClick={() => {
          setCartVisible(false);
          window.scrollTo(0, 0);
          navigate('/checkout');
        }} icon={'shopping_cart_checkout'} title={t('CHECKOUT')} />
      </SlideBar>
    </div>
  );
};

export default Header;
