import './Home.css';
import React from 'react';
import { useGetBanner, useGetRealm, useGetStaticPageByKey } from 'src/api/backend-api';
import { useEffect } from 'react';
import { useState } from 'react';
import Banner from 'src/components/Banner/Banner';
import { useAddPopup, useSetNotificationBadge } from 'src/state/application/hooks';
import { useGetBadge } from 'src/api/notificationApi';
import useProfile from 'src/hooks/useProfile';
import { useGetCategory } from 'src/api/productCategoryApi';
import { CategoryType, RealmType, StaticPageType } from 'src/api/models';
import Realms from 'src/components/Realms/Realms';
import Categories from 'src/components/Categories/Categories';
import Products from 'src/components/Products/Products';
import { useTranslation } from 'react-i18next';
import CompanyImage from 'src/components/CompanyImage/CompanyImage';
import FeaturedTab from 'src/components/FeaturedTab/FeaturedTab';

const Home: React.FC = () => {
  //Define
  const profile = useProfile();
  const { t, i18n } = useTranslation();

  //State
  const [banners, setBanners] = useState([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [category, setCategory] = useState<CategoryType>();
  const [realms, setRealms] = useState<RealmType[]>([]);
  const [realm, setRealm] = useState<RealmType>();
  const [staticPage, setStaticPage] = useState<StaticPageType>();

  const getBanner = useGetBanner();
  const getRealm = useGetRealm();
  const addPopup = useAddPopup();
  const getBadge = useGetBadge();
  const setNotificationBadge = useSetNotificationBadge();
  const getCategories = useGetCategory();
  const getStaticPageByKey = useGetStaticPageByKey();

  useEffect(() => {
    getBanner().then(res => setBanners(res));
    getCategories().then(res => setCategories(res));
    getRealm().then(res => {
      setRealms(res);
    });
    if (profile) getBadge().then(res => setNotificationBadge(res));
    getStaticPageByKey("NEWS_EXTRA_INFO_GENERAL").then((res) => setStaticPage(res));
  }, [addPopup, getBadge, getBanner, getCategories, getRealm, getStaticPageByKey, profile, setNotificationBadge]);

  //Main
  return (
    <>
      <div className="banner-home-container">
        <Banner key="banner 1" listImages={banners} startNumber={1} />
      </div>
      <div className='home-info'>
        <Categories categories={categories} selectedCategory={category} onSelect={(item) => {
          setRealm(undefined);
          setCategory(!category || item.productCategoryId != category.productCategoryId ? item : undefined);
        }} />
      </div>
      <div className='home-info'>
        <Realms realms={realms} selectedRealm={realm} onSelect={(item) => {
          setCategory(undefined);
          setRealm(!realm || item.productRealmId != realm.productRealmId ? item : undefined);
        }} />
      </div>
      <div className="realm-description">{realm?.description ?? t('HOME_TEXT_02')}</div>
      <Products categoryId={category?.productCategoryId} realmId={realm?.productRealmId} />
      <div className='inline home-news'>
        <FeaturedTab />
        <div className='hidden-small'>
          {staticPage ? (
            <div
              dangerouslySetInnerHTML={{
                __html: eval(`staticPage?.${i18n.language}??''`)
              }}
            />
          ) : null}

        </div>

      </div>

      <CompanyImage />
    </>
  );
};

export default Home;
