/* eslint-disable react-hooks/exhaustive-deps */
import './Library.css';
import React from 'react';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { useState } from 'react';
import { ImageType, LibraryType } from 'src/api/models';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetLibraries, useGetLibraryById } from 'src/api/libraryApi';
import { useAddPopup } from 'src/state/application/hooks';
import FullSizeImage from 'src/components/FullSizeImage/FullSizeImage';
import useModal from 'src/hooks/useModal';

const Library: React.FC = () => {

  const { t, i18n } = useTranslation();
  //State
  const [libraryId, setLibraryId] = useState<number>();
  const [libraries, setLibraries] = useState<LibraryType[]>();
  const [images, setImages] = useState<ImageType[]>([]);

  //Function
  const getLibraries = useGetLibraries();
  const getLibraryById = useGetLibraryById();
  const addPopup = useAddPopup();
  const fullsizeModal = useModal(FullSizeImage, undefined, false);

  useEffect(() => {
    getLibraries()
      .then((res) => {
        setLibraries(res);
        if (!libraryId && res.length > 0) setLibraryId(res[0].libraryId);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: 'Đã có lỗi xảy ra',
            title: error.message,
          },
        });
      });
  }, [addPopup, getLibraries]);

  useEffect(() => {
    if (libraryId > 0) {
      getLibraryById(libraryId)
        .then((res) => {
          setImages(res.images);
        })
        .catch((error) => {
          addPopup({
            error: {
              message: 'Đã có lỗi xảy ra',
              title: error.message,
            },
          });
        });
    }

  }, [libraryId, addPopup, getLibraryById]);

  //Main
  return (
    <PageContainer>
      <PageHeader>
        <NavLink to={'/'}>{t("Home")}</NavLink>{` / `}{t("Thư viện ảnh")}
      </PageHeader>
      <div className='library-page-container'>
        <div className="library-page-title">
          <h4>THƯ VIỆN ẢNH</h4>
        </div>
        <div className={`libraries`}>
          {libraries?.length > 0 ? libraries.map((library, index) =>
            <figure key={`library${index}`} className={`library-item ${libraryId == library.libraryId ? 'active' : ''}`} onClick={() => setLibraryId(library.libraryId)}>
              <img src={library.thumbAvatar} alt="sample41" />
              <figcaption>
                <h5>{t(library.title)}</h5>
              </figcaption>
            </figure>
          ) : null}
        </div>
        <div className="library-image-content">
          {images.map((libraryImage: ImageType, index: number) => {
            return (
              <div className="library-image-child" key={`libraryimage${index}`}>
                <img src={libraryImage.fileUrl} onClick={() => {
                  fullsizeModal.handlePresent({
                    images: images,
                    index: index,
                    hidden: fullsizeModal.handleDismiss
                  })
                }} />
              </div>
            );
          })}
        </div>

      </div>
    </PageContainer>

  );
};

export default Library;
