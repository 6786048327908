import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Verify from "src/views/AuthScreen/ChangePassword/ChangePassword";
import ConfirmForgotPassword from "src/views/AuthScreen/ConfirmForgotPassword/ConfirmForgotPassword";
import ConfirmRegister from "src/views/AuthScreen/ConfirmRegister/ConfirmRegister";
import ForgotPassword from "src/views/AuthScreen/ForgotPassword/ForgotPassword";
import Login from "src/views/AuthScreen/Login/Login";
import Register from "src/views/AuthScreen/Register/Register";
import Checkouts from "src/views/Checkouts/Checkouts";
import Home from "src/views/Home/Home";
import News from "src/views/News/News";
import OrderRequirement from "src/views/OrderRequirement/OrderRequirement";
import OrderRequirementDetail from "src/views/OrderRequirement/OrderRequirementDetail/OrderRequirementDetail";
// import ProductCategory from "src/views/ProductCategory/ProductCategory";
import ProductDetail from "src/views/ProductDetail/ProductDetail";
import StaticPage from "src/views/StaticPage/StaticPage";
import Tracking from "src/views/Tracking/Tracking";
import Notification from 'src/views/Notification/Notification';
import Header from "src/components/Header/Header";
import ChatConsultation from "src/components/ChatConsultation/ChatConsultation";
import Footer from "src/components/Footer/Footer";
import { useEffect, useState } from "react";
import Chat from "src/components/Chat/Chat";
import useProfile from "src/hooks/useProfile";
import NewDetail from "src/views/News/NewDetail/NewDetail";
import Partner from "src/components/Partner/Partner";
import Library from "src/views/Library/Library";
import ProductPage from "src/views/ProductPage/ProductPage";
import Experience from "src/views/Experience/Experience";
import ExperienceDetail from "src/views/Experience/ExperienceDetail/ExperienceDetail";
import Destination from "src/views/Destination/Destination";
import DestinationDetail from "src/views/Destination/DestinationDetail/DestinationDetail";
const AppRouters: React.FC = () => {

  //State
  const [consultationAvailable, setConsultationAvailable] = useState(true);
  const location = useLocation();
  const [isAuth, setAuth] = useState(true);
  const profile = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes("auth-screen")) {
      setAuth(true);
      if (profile) navigate('/');
    } else {
      setAuth(false);
    }

  }, [location, navigate, profile])

  //Main
  return (
    <>
      {!isAuth && <Header />}

      <Routes>
        <Route path="/auth-screen/confirm-register" element={<ConfirmRegister />} />
        <Route path="/auth-screen/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth-screen/register" element={<Register />} />
        <Route path="/auth-screen/confirm-forgot-password" element={<ConfirmForgotPassword />} />
        <Route path="/auth-screen/change-password" element={<Verify />} />
        <Route path="/auth-screen" element={<Login />} />
        <Route path="/" element={<Home />} />
        {/* <Route path="/product-category" element={<ProductCategory />} /> */}
        <Route path="/product-detail/:productId" element={<ProductDetail />} />
        <Route path="/product/:type/:id" element={<ProductPage />} />
        <Route path="/checkout" element={<Checkouts />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/order-requirement" element={<OrderRequirement />} />
        <Route path="/order-requirement/:orderRequirementId" element={<OrderRequirementDetail />} />
        <Route path="/static-page/:key" element={<StaticPage />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewDetail />} />
        <Route path="/tracking" element={<Tracking />} />
        <Route path="/library" element={<Library />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/experience/:id" element={<ExperienceDetail />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/destination/:id" element={<DestinationDetail />} />
      </Routes>

      {!isAuth && consultationAvailable ? <ChatConsultation /> : null}
      {!isAuth && <Chat setConsultationAvailable={setConsultationAvailable} />}
      {!isAuth && <Partner />}
      {!isAuth && <Footer />}
    </>

  );
};

export default AppRouters;
