import React from 'react';
import { useLoadingFlag } from 'src/state/application/hooks';
import styled from 'styled-components';
import Loading from '../Loading';

interface IButtonComponent {
  width?: string;
  color?: string;
  height?: string;
  background?: string;
  backgroundHover?: string;
  onClick: () => void;
  title?: string;
  icon?: string;
}

const ButtonComponent: React.FC<IButtonComponent> = (props) => {
  const { width, height, background, backgroundHover, color, title, icon, onClick } = props;
  const loadingFlag = useLoadingFlag();

  return (
    <StyledButton
      width={width}
      height={height}
      background={background}
      backgroundHover={backgroundHover}
      color={color}
      onClick={onClick}
    >
      {loadingFlag ? <Loading /> : icon ? <StyledIcon className="material-icons">{icon}</StyledIcon> : null}
      <StyledLabel>{title}</StyledLabel>
    </StyledButton>
  );
};

export default ButtonComponent;

const StyledButton = styled.div<{ width?: string, height?: string, background?: string, color?: string, backgroundHover?: string }>`
  background-color: ${({ background }) => background ? background : 'rgb(17, 17, 17)'};
  color: ${({ color }) => color ? color : 'white'};
  padding: 10px;
  margin: 0 min(2vw, 30px);
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  :hover {
    background-color: ${({ backgroundHover }) => backgroundHover ? backgroundHover : 'rgb(26, 23, 23)'};
    color: ${({ color }) => color ? color : 'white'};
    cursor: pointer;
  }
`;

const StyledIcon = styled.span`
  vertical-align: middle;
  margin: auto 5px;
`

const StyledLabel = styled.label`
  vertical-align: middle;
  margin: auto 5px;
  padding-bottom: 2px;
`
