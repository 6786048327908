import './ExperienceDetail.css';
import React from 'react';
import { useParams } from 'react-router';
import {
  useGetExperienceById,
} from 'src/api/experienceApi';
import { useEffect, useState } from 'react';
import { ExperienceType } from 'src/api/models';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ExperienceDetail: React.FC = () => {
  const params = useParams<{ id: string }>();
  const experienceId = Number(params.id);
  const { t, i18n } = useTranslation();
  //
  const [experienceDisplay, setExperienceDisplay] = useState<ExperienceType>();
  //Function
  const getExperienceById = useGetExperienceById();

  //Get Experience
  useEffect(() => {
    getExperienceById(experienceId)
      .then((data) => {
        setExperienceDisplay(data);
      });
  }, [getExperienceById, experienceId]);


  return (
    <PageContainer>
      <PageHeader>
        <NavLink to={'/'}>{t("Home")}</NavLink>{` / `}<NavLink to={'/experience'}>{t("Trải nghiệm")}</NavLink>{` / `}{t(experienceDisplay?.experienceTitle)}
      </PageHeader>
      <div className="experience-value">
        <div dangerouslySetInnerHTML={{ __html: eval(`experienceDisplay?.${i18n.language}??''`) }} />
      </div>
    </PageContainer>
  );
};

export default ExperienceDetail;
