import { MenuType } from 'src/api/models';
import './VerticalMenu.css';
import React from 'react';
import VerticalMenuItem from '../VerticalMenuItem/VerticalMenuItem';

interface IVerticalMenu {
  menus: MenuType[];
  hidden: () => void;
}

const VerticalMenu: React.FC<IVerticalMenu> = (props) => {
  const { menus, hidden } = props;

  //Main
  return (
    menus?.length > 0 ?
      <>
        {menus.map((item: MenuType, index: number) => {
          return <VerticalMenuItem key={`vertical-menu-${index}`} menu={item} hidden={hidden} />;
        })}
      </> : null
  );
};

export default VerticalMenu;
