import './Categories.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryType } from 'src/api/models';

interface ICategories {
  categories: CategoryType[];
  selectedCategory?: CategoryType;
  onSelect: (category: CategoryType) => void;
}


const Categories: React.FC<ICategories> = (props) => {
  const { t } = useTranslation();
  const { categories, selectedCategory, onSelect } = props;

  //Main
  return (
    <div className={`categories-container`}>
      <div className="categories-header">
        <div className="categories-title">{t("HOME_TEXT_01")}</div>
      </div>
      <div className="category-list-cpn">
        {categories.map((item: CategoryType, index: number) => {
          return (
            <div
              key={`categoryitemcomponent${item.productCategoryId}${index}`}
              className={`category-item-ctn ${item.productCategoryId == selectedCategory?.productCategoryId ? 'focus' : ''
                }`}
              title={t(item.productCategoryName)}
              onClick={() => onSelect(item)}
            >
              <div className="category-item-img">
                {item.thumbAvatar ? <img src={item.thumbAvatar} alt="avatar" /> : null}
              </div>
              <div className="category-item-name">{t(item.productCategoryName)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
