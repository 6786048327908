import { useTranslation } from 'react-i18next';
import './FeaturedNews.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetNews } from 'src/api/backend-api';
import { NewsType } from 'src/api/models';


const FeaturedNews: React.FC = () => {
  //Value
  const navigate = useNavigate();
  //State
  const [firstNew, setFirstdNew] = useState<NewsType>();
  const [news, setNews] = useState<NewsType[]>([]);

  const getNews = useGetNews();

  const { t } = useTranslation();

  //End of function
  useEffect(() => {
    getNews(1, 7)
      .then((data) => {
        if (data.items.length > 0) {
          setFirstdNew(data.items[0]);
          setNews(data.items.slice(1));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getNews]);

  //Main
  return (
    <div className="featured-news-container">
      {firstNew ? <div className='first-new-container' onClick={() => navigate(`/news/${firstNew.newId}`)}>
        <img className="first-new-avatar" src={firstNew.newAvatar} />
        <div className="first-new-content">
          <div className="first-news-item-title">{t(firstNew.newTitle)}</div>
          <div className="first-news-item-des">{t(firstNew.description)}</div>
        </div>
      </div> : null}

      <div className="featured-news-child">
        {news.map((item: NewsType, index: number) => {
          return (
            <div
              key={`feature${index}`}
              className="feature-new-item feature-new-resize"
              onClick={() => navigate(`/news/${item.newId}`)}
              title={t(item.newTitle)}
            >
              <img className="feature-new-avatar" src={item.newAvatar} />
              <div className="featured-news-item-title">{t(item.newTitle)}</div>
            </div>
          );
        })}

      </div>

    </div>
  );
};

export default FeaturedNews;
