/* eslint-disable react-hooks/exhaustive-deps */
import './Checkouts.css';
import React, { useEffect, useRef, useState } from 'react';
import {
  useAddPopup,
  useCleanCart,
  useGetCart,
  useSetNotificationBadge,
} from 'src/state/application/hooks';
import {
  CountryType,
  EnumReceiveTime,
  EnumReceiveTimeTitle,
  OrderRequirementDetailsItemType,
  OrderRequirementType,
  ReceiverInfoType,
} from 'src/api/models';
import { CartItemType } from 'src/state/application/models';
import { useDeleteReceiverInfo, useGetMyReceiverInfo, useGetReceiverInfoById, usePostReceiverInfo, usePutReceiverInfo } from 'src/api/receiverInfoApi';
import CartReceiverInfoComponent from 'src/components/CartReceiverInfoComponent/CartReceiverInfoComponent';
import { useGetShipInfo } from 'src/api/commonInfomationApi';
import Calendar, { EnumCalendarAlign, EnumCalendarPos } from 'src/components/Calendar/Calendar';
import useProfile from 'src/hooks/useProfile';
import { Navigate, useNavigate } from 'react-router';
import Images from 'src/assets/img';
import { usePostOrderRequirement } from 'src/api/orderRequirementApi';
import { useGetNotification } from 'src/api/notificationApi';
import { useReadQRCode } from 'src/api/qrApi';
import { uniqueId } from 'lodash';
import styled from 'styled-components';
import { normalizationZipcode, number2money } from 'src/utils/stringUtils';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import { NavLink } from 'react-router-dom';
import Cart from 'src/components/Cart/Cart';
import Input from 'src/components/Input/Input';
import SelectSearch from 'src/components/SelectSearch/SelectSearch';
import { useGetCountries } from 'src/api/countryApi ';
import CalendarInput from 'src/components/CalendarInput/CalendarInput';
import OptionComponent from 'src/components/OptionComponent/OptionComponent';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import { useTranslation } from 'react-i18next';

const Checkouts: React.FC = () => {
  //Value
  const cart = useGetCart();
  const profile = useProfile();
  const navigate = useNavigate();

  const { t } = useTranslation();

  //Receiver
  const [receiverFullName, setReceiverFullName] = useState<string>('');
  const [receiverFullnameError, setReceiverFullnameError] = useState(null);
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState<string>('');
  const [receiverFacebook, setReceiverFacebook] = useState<string>('');
  const [receiverContactError, setReceiverContactError] = useState(null);
  const [orderRequirementNote, setOrderRequirementNote] = useState<string>('');
  const [receiverInfoList, setReceiverInfoList] = useState<ReceiverInfoType[]>([]);

  const [totalMoney, setTotalMoney] = useState(0);

  const [checkReceiverId, setCheckReceiverId] = useState<number>();

  const [collapse, setCollapse] = useState(false);
  const [contries, setCountries] = useState<CountryType[]>([]);
  //const [isShip, setIsShip] = useState(false);
  //End of state

  //Function
  const getMyReceiverInfo = useGetMyReceiverInfo();
  const postOrderRequirement = usePostOrderRequirement();
  const addPopup = useAddPopup();
  const cleanCart = useCleanCart();
  const getNotification = useGetNotification();
  const setNotificationBadge = useSetNotificationBadge();
  const getCountries = useGetCountries();
  const postReceiverInfo = usePostReceiverInfo();
  const putReceiverInfo = usePutReceiverInfo();
  const deleteReceiverInfo = useDeleteReceiverInfo();


  const checkFuncReceiver = (item: ReceiverInfoType) => {
    setCheckReceiverId(item.receiverInfoId);
    setReceiverFullName(item.fullname);
    setReceiverPhoneNumber(item.phoneNumber);
    setReceiverFacebook(item.facebook);
  };

  const onPostReceiverInfo = () => {
    if (validateReceiverFullName()
      && validateReceiverContact()) {
      const receiverInfo: ReceiverInfoType = {
        fullname: receiverFullName,
        phoneNumber: receiverPhoneNumber,
        facebook: receiverFacebook,
      };
      postReceiverInfo(receiverInfo)
        .then((res) => {
          setReceiverInfoList([...receiverInfoList, res]);
          setCheckReceiverId(res.receiverInfoId);
          setReceiverFullName(res.fullname);
          setReceiverPhoneNumber(res.phoneNumber);
          setReceiverFacebook(res.facebook);
          addPopup({
            txn: {
              success: true,
              summary: t('Create new information successfully'),
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.message,
              title: t('Create new information failed'),
            },
          });
        });
    }
  };

  const onPutReceiverInfo = () => {
    if (validateReceiverFullName()
      && validateReceiverContact()) {
      const receiverInfo: ReceiverInfoType = {
        fullname: receiverFullName,
        phoneNumber: receiverPhoneNumber,
        facebook: receiverFacebook,
        receiverInfoId: checkReceiverId,
      };
      putReceiverInfo(receiverInfo)
        .then((res) => {
          const indx = receiverInfoList.findIndex(r => r.receiverInfoId == checkReceiverId);
          receiverInfoList[indx] = res;
          setReceiverInfoList([...receiverInfoList]);
          setReceiverFullName(res.fullname);
          setReceiverPhoneNumber(res.phoneNumber);
          setReceiverFacebook(res.facebook);
          addPopup({
            txn: {
              success: true,
              summary: t('Change new information successfully'),
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.message,
              title: t('Change new information failed'),
            },
          });
        });
    }
  };

  const onDeleteReceiverInfo = () => {
    deleteReceiverInfo(checkReceiverId).then(() => {
      const nReceiverInfos = receiverInfoList.filter(r => r.receiverInfoId != checkReceiverId);
      setCheckReceiverId(undefined);
      setReceiverInfoList([...nReceiverInfos]);
    });
  };


  //Validate
  const validateReceiverFullName = () => {
    const bOk = receiverFullName && receiverFullName != '';
    setReceiverFullnameError(!bOk ? t('Chưa nhập tên người đăng ký') : undefined);
    return bOk;
  };

  const validateReceiverContact = () => {
    const bOk =
      (receiverPhoneNumber && receiverPhoneNumber != '') ||
      (receiverFacebook && receiverFacebook != '');
    setReceiverContactError(
      !bOk ? t('Did not enter contact information, please  enter phone number or facebook info') : undefined,
    );
    return bOk;
  };
  //End of validate

  const onPostOrderRequirement = () => {
    const isReceiverFullname = validateReceiverFullName();
    const isReceiverContact = validateReceiverContact();
    if (isReceiverFullname && isReceiverContact) {
      const orderRequirementDetails: OrderRequirementDetailsItemType[] = [];
      cart.map((item: CartItemType) => {
        const detail: OrderRequirementDetailsItemType = {
          productId: item.productId,
          option: item.option,
          quantity: item.quantity
        };
        orderRequirementDetails.push(detail);
      });
      const oderRequirement: OrderRequirementType = {
        receiverFullname: receiverFullName,
        receiverPhoneNumber: receiverPhoneNumber,
        receiverFacebook: receiverFacebook,
        orderRequirementNote: orderRequirementNote,
        orderRequirementDetails: orderRequirementDetails,
      };
      postOrderRequirement(oderRequirement)
        .then(() => {
          navigate('/order-requirement');
          addPopup({
            txn: {
              success: true,
              summary: t('Your registry has been sent successfully, we will contact you as soon as possible'),
            },
          });
          cleanCart();
        })
        .catch((error) => {
          console.log(error);
          addPopup({
            error: {
              message: error.errorMessage,
              title: t('An error has occurred'),
            },
          });
        });

    } else {
      addPopup({
        txn: {
          success: false,
          summary: t('Please enter enough information.'),
        },
      });
    }
  };
  //End of function


  useEffect(() => {

    getMyReceiverInfo()
      .then((data) => {
        setReceiverInfoList(data);
      });
    getCountries().then((res) => setCountries(res));
  }, [getMyReceiverInfo]);


  useEffect(() => {
    if (profile) {
      getNotification(1, 1)
        .then((data) => {
          setNotificationBadge(data.badge);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getNotification, profile, setNotificationBadge]);



  //Main
  return (
    profile ? cart.length > 0 ?
      <PageContainer>
        <PageHeader>
          <NavLink to={'/'}>{t("Home")}</NavLink>{` / `}{t("Checkout")}
        </PageHeader>
        <div className="checkout-container">
          <div className={`cart-info`}>
            <div className="cart-info-header">
              <div><span className="material-icons cart-info-header-middle">shopping_cart</span><label className="cart-info-header-middle">{cart.length} {t("items")}</label> </div>
              <span className="material-icons cart-info-header-middle" onClick={() => setCollapse(!collapse)}>{collapse ? 'arrow_drop_down' : 'arrow_drop_up'}</span>
            </div>
            <div className={collapse ? 'collapse' : 'uncollapse'}>
              <Cart ref={(el: any) => {
                if (el) setTotalMoney(el.getSubTotal());
              }} />
              <hr />
              <div className='total-title'><label> {t("Total")}</label><span>{totalMoney ? number2money(totalMoney) : t("Liên hệ")}</span></div>
            </div>

          </div>
          <div className="delivery">
            <div className="receiver-info">
              {receiverInfoList.length > 0 && <label>{t("Choose one of the information below or enter your information")}</label>}
              <div className="receiver-info-list">
                {receiverInfoList.map((value, index) => {
                  return (
                    <CartReceiverInfoComponent
                      key={`receiveritem${index}`}
                      data={value}
                      checked={value.receiverInfoId == checkReceiverId}
                      checkFunc={() => checkFuncReceiver(value)}
                    />
                  );
                })}
              </div>
              {checkReceiverId ? <label className='delete-address' onClick={onDeleteReceiverInfo}>{t("Delete information")}</label> : null}
            </div>
            <div className="delivery-info">
              <div className="delivery-address">
                <div>1. {t("Information")}</div>
                <Input
                  leftIcon='fas fa-user'
                  placeholder={t('Fullname')}
                  value={receiverFullName}
                  errorMessage={receiverFullnameError}
                  type="text"
                  onChange={setReceiverFullName}
                  onBlur={validateReceiverFullName}
                />
                <Input
                  leftIcon='fas fa-phone'
                  placeholder={t('Phone')}
                  value={receiverPhoneNumber}
                  errorMessage={receiverContactError}
                  type="text"
                  onChange={setReceiverPhoneNumber}
                  onBlur={validateReceiverContact}
                />
                <Input
                  leftIcon='fab fa-facebook-f'
                  rightIcon='fas fa-info-circle'
                  rightAction={() => window.open('/static-page/FACEBOOK_INFO_GUIDE')}
                  placeholder={t('Facebook')}
                  value={receiverFacebook}
                  errorMessage={receiverContactError}
                  type="text"
                  onChange={setReceiverFacebook}
                  onBlur={validateReceiverContact}
                />

                <label className='save-address' onClick={checkReceiverId ? onPutReceiverInfo : onPostReceiverInfo}>{t("Save your information")}</label>
              </div>
              <div className="delivery-time">
                <div>2. {t("Other information")}</div>
                <textarea
                  className='note-checkout'
                  value={orderRequirementNote}
                  onChange={(event) => setOrderRequirementNote(event.target.value)}
                  placeholder={t('Note...')}
                  rows={3}
                />
                <ButtonComponent onClick={onPostOrderRequirement} icon={'shopping_cart_checkout'} title={t("PURCHASE")} />
              </div>
            </div>
          </div>

        </div>
      </PageContainer >
      : <Navigate to="/" />
      : <Navigate to="/auth-screen" />
  );

};

export default Checkouts;
