import './Realms.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RealmType } from 'src/api/models';

interface IRealms {
  realms: RealmType[];
  selectedRealm?: RealmType;
  onSelect: (realm: RealmType) => void;
}


const Realms: React.FC<IRealms> = (props) => {
  const { t } = useTranslation();
  const { realms, selectedRealm, onSelect } = props;

  //Main
  return (
    <div className={`realms-container`}>
      {realms.map((item, index) => {
        return (
          <div key={`realms${index}`}
            className={`realms-title ${item.productRealmId == selectedRealm?.productRealmId ? 'focus' : ''} ${index == 0 ? 'left' : index == realms.length - 1 ? 'right' : ''}`}
            onClick={() => onSelect(item)}
          >
            {t(item.productRealmName)}
          </div>
        );
      })}
    </div>
  );
};

export default Realms;
