/* eslint-disable react-hooks/exhaustive-deps */
import './Experience.css';
import React from 'react';
import { useNavigate } from 'react-router';
import {
  useGetExperience
} from 'src/api/experienceApi';
import { useEffect, useState } from 'react';
import { ExperienceType, StaticPageType } from 'src/api/models';
import { PageContainer, PageHeader } from 'src/components/GlobalStyled';
import { NavLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'src/components/Loading';
import { useTranslation } from 'react-i18next';
import { useGetStaticPageByKey } from 'src/api/backend-api';

const Experience: React.FC = () => {
  //Value
  const navigate = useNavigate();
  const SIZE = 20;
  //State
  const [listExperience, setListExperience] = useState<ExperienceType[]>([]);
  const [experienceListAds, setExperienceListAds] = useState<StaticPageType>();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(true);

  //Function
  const getExperience = useGetExperience();
  const getStaticPageByKey = useGetStaticPageByKey();
  const { t, i18n } = useTranslation();
  //End of function

  //Get ads
  useEffect(() => {
    getStaticPageByKey('NEWS_EXTRA_INFO_LIST')
      .then((data) => {
        setExperienceListAds(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getStaticPageByKey]);



  const fetchData = (reset: boolean) => {
    setLoadingFlag(true);
    const cpage = reset ? 1 : page;
    getExperience(cpage, SIZE)
      .then((r) => {
        const nList = reset ? r.items : [...listExperience, ...r.items];
        console.log(nList);
        setListExperience(nList);
        if (nList.length >= r.count) {
          setHasMore(false);
        } else {
          setHasMore(true);
          setPage(page + 1);
        }
      })
      .finally(() => setLoadingFlag(false));
  };


  //Get Experience
  useEffect(() => {
    fetchData(true);
  }, [reloadFlag]);


  return (

    <PageContainer>
      <PageHeader>
        <NavLink to={'/'}>{t("Home")}</NavLink>{` / `}{t("Trải nghiệm")}
      </PageHeader>
      <div className='experience-container'>
        <InfiniteScroll
          dataLength={listExperience.length}
          height='fit-content'
          next={() => fetchData(false)}
          hasMore={hasMore}
          className='list-experience'
          loader={<div>Đang tải</div>}
        >
          {listExperience.map((item: ExperienceType, index: number) => {
            return (
              <div
                key={`experience${index}`}
                className="experience-item"
                onClick={() => navigate(`/experience/${item.experienceId}`)}
                title={t(item.experienceTitle)}
              >
                <img className="experience-avatar" src={item.avatar} />
                <div className='experience-item-content'>
                  <div className="experience-item-title">{t(item.experienceTitle)}</div>
                  <div className="experience-item-des">{t(item.description)}</div>
                </div>

              </div>
            );
          })}
          {loadingFlag && <div className='refresh-loading'>
            <Loading color='gray' />
          </div>}
        </InfiniteScroll>


        {experienceListAds ? <div className='experience-extra-hidden hidden-small' dangerouslySetInnerHTML={{ __html: eval(`experienceListAds?.${i18n.language}??''`) }} /> : null}
      </div>

    </PageContainer>

  );
};

export default Experience;
