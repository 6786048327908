import { useTranslation } from 'react-i18next';
import './FeaturedExperience.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetExperience } from 'src/api/experienceApi';
import { ExperienceType } from 'src/api/models';


const FeaturedExperience: React.FC = () => {
  //Value
  const navigate = useNavigate();
  //State
  const [firstExperience, setFirstdExperience] = useState<ExperienceType>();
  const [featuredExperience, setFeaturedExperience] = useState<ExperienceType[]>([]);

  const getExperience = useGetExperience();

  const { t } = useTranslation();

  //End of function
  useEffect(() => {
    getExperience(1, 7)
      .then((data) => {
        if (data.items.length > 0) {
          setFirstdExperience(data.items[0]);
          setFeaturedExperience(data.items.slice(1));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getExperience]);

  //Main
  return (
    <div className="featured-experience-container">
      {firstExperience ? <div className='first-experience-container' onClick={() => navigate(`/experience/${firstExperience.experienceId}`)}>
        <img className="first-experience-avatar" src={firstExperience.avatar} />
        <div className="first-experience-content">
          <div className="first-experience-item-title">{t(firstExperience.experienceTitle)}</div>
          <div className="first-experience-item-des">{t(firstExperience.description)}</div>
        </div>
      </div> : null}

      <div className="featured-experience-child">
        {featuredExperience.map((item: ExperienceType, index: number) => {
          return (
            <div
              key={`feature${index}`}
              className="feature-experience-item feature-experience-resize"
              onClick={() => navigate(`/experience/${item.experienceId}`)}
              title={t(item.experienceTitle)}
            >
              <img className="feature-experience-avatar" src={item.avatar} />
              <div className="featured-experience-item-title">{t(item.experienceTitle)}</div>
            </div>
          );
        })}

      </div>

    </div>
  );
};

export default FeaturedExperience;
